#root {
  max-width: 100vw;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
}

main {
  background: white;
  background-size: 200% 200%;
  animation: gradientAnimation 5s linear infinite;
}

body {
  background-size: 200% 200%;
  animation: gradientAnimation 5s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

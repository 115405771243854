@use '@gear-js/ui/variables' as *;
@use 'assets/styles/shared' as *;

.loader {
  @extend %absoluteCenter;
  font-size: $fontSizeLarge;

  &::after {
    content: '.';
    width: 1em;
    display: inline-block;
    text-align: left;
    animation: ellipsis 1.25s infinite;
  }
}

@keyframes ellipsis {
  33% {
    content: '..';
  }

  66% {
    content: '...';
  }
}
